// Package imports:
import React from 'react';
// Component imports:
import Button from '../../../ui-elements/Button/Button';

interface IProps {
    boxType: 'owners' | 'valuation'
}

const BlurredCompanyInfoBox: React.FC<IProps> = ({
    boxType
}) => {
    return (
        <div className='KCL_blurred-company-info-box'>
            <p className='text paragraph-small--bold'>
                Skráðu þig inn til að sjá {boxType === 'owners' ? 'raunverulega eigendur' : 'áætlað verðmæti'}
            </p>
            <div className="buttons">
                <Button buttonType='secondary' anchorProps={{ href: `/Innskraning?ReturnUrl=${window.location.pathname}`}}>Innskrá</Button>
                <Button anchorProps={{ href: '/Verdskra#Gagnaleit'}}>Nýskrá</Button>
            </div>
        </div>
    );
}

export default BlurredCompanyInfoBox;
