// Package imports:
import React, { useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import Bugsnag from '@bugsnag/js';
import * as Yup from 'yup';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import Input from '../../ui-elements/Input/Formik/Input';
import Loading from '../../ui-elements/Loading/Loading';
import Link from '../../ui-elements/Link/Link';
import Alert from '../../ui-elements/Alert/Alert';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../ui-elements/Table/Table';
import Tooltip from '../../ui-elements/Tooltip/Tooltip';
import {DatePickerField} from '../../ui-elements/DatePicker/Formik/DatePicker'; 
import FilterItem from '../../ui-elements/Filter/FilterItem/FilterItem';
import SearchResults from '../../ui-elements/SearchResults/SearchResults';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { getTableDate } from '../../services/utils';
import { useBuyingProcessUrl } from '../../services/buyhook';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { IKeldanApiResponse } from '../../types/KeldanTypes';
import { LegalAdvert, LegalSearchViewModel } from '../../types/LegalTypes';

interface IAdvertTypesFilter {
    type: string,
    isOn: boolean
}

type DataStatus = 'no filter selected' | 'complete' | 'no data' ;

interface IDataMemo {
    dataToDisplay: LegalAdvert[] | null,
    dataStatus: DataStatus
};
interface IProps {
    isAuthenticated: boolean
}

const LegalSearch: React.FC<IProps> = ({ isAuthenticated }) => {
    const [ data, setData] = useState<LegalAdvert[]>();
    const [ types, setTypes] = useState<IAdvertTypesFilter[]>();
    const [ searchTerm, setSearchTerm] = useState('');

    const searchResultsRef = useRef<HTMLDivElement>(null);

    const usingSourceFilter = useMemo(() => {
        if (types === null || types === undefined || types instanceof Error) return 'all';
        if (types.every(types => !types.isOn)) return 'none';
        if (types.every(types => types.isOn)) return 'all';
        return 'some';
    }, [ types ]);

    const isSearchLoading = useMemo(() => {
        return (data === undefined && searchTerm.length > 0)
    }, [ data, searchTerm ])

    const { tryPurchaseItem, isItemLoading, setToastError, ModalsAndToasts } = useBuyingProcessUrl((legalAdvert: LegalAdvert, reference: string) => {
        const { advertNumber } = legalAdvert;
        const url = (reference === '') 
            ? `${GET_KELDAN_API_URL()}/Legal/BuyReport/?adNumber=${advertNumber}`
            :`${GET_KELDAN_API_URL()}/Legal/BuyReport/?adNumber=${advertNumber}&reference=${reference}`
        return url;
        },
        () => {},
        'Lögbirtingablaðinu'
    );

    const handleSearch = async (values: IFormValues) => {
        setData(undefined);
        setSearchTerm(values.term)
        try {
            const requestQuery = new URLSearchParams({
                term: values.term,
            })
            if (values.dateFrom) requestQuery.append('FromDateString', getTableDate(values.dateFrom,"DD/MM/YYYY", "."))
            if (values.dateTo) requestQuery.append('ToDateString', getTableDate(values.dateTo,"DD/MM/YYYY", "."))
            const url = `${GET_KELDAN_API_URL()}/Legal/Search`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                setToastError('response not ok');
                return;
            }
            //user is not logged in -> redirect to login page
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<LegalSearchViewModel> = await response.json();
            
            if (responseBody.success) {
                if (responseBody.result) {
                    setData(responseBody.result.result);
                    const typeList = responseBody.result.typeList.advertTypes;
                    const typesInResults = responseBody.result.result.map(x => x.type);
                    const newTypeList = typeList.filter(x => typesInResults.includes(x));
                    const filteredResultsFilters: IAdvertTypesFilter[] = newTypeList.map(type => ({
                        type,
                        isOn: true
                    }));
                    setTypes(filteredResultsFilters);
                    searchResultsRef.current?.scrollIntoView();
                }
            } else {
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    setToastError('no card');
                } else if (message) {
                    setToastError({
                        type: 'alert',
                        headText: message
                    })
                } else {
                    setToastError('bad request')
                }
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setToastError('network error')
        }
    }

    const dataToDisplay = useMemo(() => {
        //initialize obj
        const dataToReturn: IDataMemo = {
            dataToDisplay: [],
            dataStatus: 'no data'
        }
        if(data?.length === 0) return dataToReturn;

        //changing status. Since data exists. 
        dataToReturn.dataStatus = 'no filter selected'
        // if no source filter selected -> then there is nothing that 'isOn' so no data is returned
        //'no filter selected' to show that there is data but none can be shown
        if (usingSourceFilter === 'none') return dataToReturn;

        if(data !== undefined && types !== undefined){
            for(let item of data){
                //loop through every instance of data and check if for a specific type if it's marked as on
                //if it is marked. Then push to array.
                if(types.find(x => x.isOn && x.type === item.type)){
                    dataToReturn.dataToDisplay?.push(item)
                }
            }
            //completed looping through
            dataToReturn.dataStatus = "complete";
        }
        return dataToReturn;
    }, [types])

    const displayForm = (type: 'disabled' | 'enabled') => (
        <Formik
            initialValues={{
                term: '',
                dateFrom: '',
                dateTo: '',
            }}
            validationSchema={Yup.object({
                term: Yup.string().required('Vinsamlegast sláið inn nafn eða kennitölu'),
                dateFrom: Yup.date().nullable().when('dateTo', {
                    is: (v: any) => v != null,
                    then: s => s.max(Yup.ref('dateTo'), `Dagsetning má ekki vera á eftir til dagsetningu`),
                }),
            })}
            onSubmit={ (e) => handleSearch(e)}
            component={(props: { values: IFormValues }) => (
                <SubFormComponent
                    values={props.values}
                    loading={isSearchLoading}
                    disabled={type === 'disabled'}
                />
            )}
        />
    )

    const displayResults = () => {
        if (dataToDisplay.dataToDisplay !== null && dataToDisplay.dataStatus === 'complete') {
            return <Table
                tableSize='lg'
                data={dataToDisplay.dataToDisplay}
                columns={[
                    {
                        title: 'dagsetning',
                        renderCell: ({ published }) => getTableDate(published, "DD/MM/YYYY", "."),
                        textAlign: 'left',
                        simpleSortable: ({ published }) => published,
                    }, {
                        title: 'tegund',
                        renderCell: ({ type }) => type,
                        textAlign: 'left',
                        simpleSortable: ({ type }) => type
                    }, {
                        title: 'efni',
                        renderCell: ({ subject }) => subject,
                        textAlign: 'left',
                        simpleSortable: ({ subject }) => subject
                    }, {
                        title: 'númer',
                        renderCell: ({ advertNumber }) => advertNumber,
                        textAlign: 'left',
                        simpleSortable: ({ advertNumber }) => advertNumber
                    }, {
                        title: 'sýnishorn',
                        renderCell: ({ highlight }) => 
                            (highlight !== null) 
                                ? highlight.substring(0, Math.min(20, highlight.length))
                                : "Á ekki við",
                        textAlign: 'left',
                    }, {
                        title: '',
                        renderCell: (advert) => (
                            <Button 
                                showLoader={isItemLoading(advert)}
                                onClick={() => {tryPurchaseItem(advert, 'confirm-modal-with-reference')}}
                                size='sm'
                                buttonType='buy'
                            >
                                Kaupa
                            </Button>
                        ),
                        textAlign: 'right'
                    }
                ]}
            />
        }
        if (isSearchLoading) return <Loading />
        if (dataToDisplay.dataStatus === 'no data') {
            return <Alert type='info' headText={`Engar niðurstöður fundust fyrir leitarorð: ${searchTerm}`} />
        }
        if (dataToDisplay.dataStatus === "no filter selected") {
            return <Alert type='info' headText='Vinsamlegast veljið tegund' />
        }
        return <Alert type='alert' headText={ErrorMessages.Other} />
    }

    return (
        <div className='main KCL_legal-search'>
            <div className='main__inner-fluid'>
                <div className="shell">
                    <HeadSection
                        title='Lögbirtingablaðið'
                        summary={
                            <p>Hér getur þú flett upp auglýsingum úr Lögbirtingablaðinu.</p>
                        }
                        description={<>
                            <p>
                                Þú getur leitað eftir einu eða fleiri leitarorðum og aðgreint með kommu á milli, 
                                til dæmis “010101-1239, nafn fyrirtækis” og einnig valið tímabil. 
                                Hægt er svo að sía niðurstöður eftir flokkum.
                            </p>
                            <Link linkSize='18' url='/Verdskra#Gagnaleit'>Sjá verðskrá.</Link>
                        </>}
                        showRecentReports={isAuthenticated}
                    />
                    <div>
                        {(isAuthenticated)
                            ? displayForm('enabled')
                            : <Tooltip
                                tooltip={
                                    <span>
                                        Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                    </span>
                                }
                                text={displayForm('disabled')}
                            />
                        }
                    </div>
                </div>
                <SearchResults
                    title={`Leitarniðurstöður fyrir ${searchTerm}`}
                    show={searchTerm !== null && searchTerm.length > 0}
                    closeResults={() => setSearchTerm('')}
                    ref={searchResultsRef}
                    component={
                        <DisplayBox>
                            {(types === undefined)
                                ? null
                                : <div className='source-filters'>
                                    <strong>Sía eftir tegund auglýsingar:</strong>
                                    <div className='filterWrapper'>
                                        <FilterItem
                                            showCheck
                                            size='lg'
                                            text="Velja allar"
                                            selected={usingSourceFilter === 'all'}
                                            toggleSelected={() => {
                                                const sourcesCopy: IAdvertTypesFilter[] = [];
                                                types.forEach((s) => {
                                                    sourcesCopy.push({
                                                        type: s.type,
                                                        isOn: usingSourceFilter !== 'all'
                                                    });
                                                });
                                                setTypes(sourcesCopy);
                                            }}
                                        />
                                        {types.map(({type, isOn }, i) =>
                                            <FilterItem
                                                showCheck
                                                size='lg'
                                                selected={isOn}
                                                text={type}
                                                toggleSelected={() => {
                                                    // Copy to prevent corruption of state.
                                                    const sourcesCopy = [...types];
                                                    sourcesCopy.splice(i, 1, {
                                                        type,
                                                        isOn: !isOn
                                                    });
                                                    setTypes(sourcesCopy);
                                                }}
                                                key={type}
                                            />
                                        )}
                                    </div>
                                </div>
                            }
                            {displayResults()}
                        </DisplayBox>
                    }
                />
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default LegalSearch;

interface IFormValues {
    term: string,
    dateFrom: string,
    dateTo: string,
}

interface ISubFormProps {
    values: IFormValues,
    loading: boolean,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className='form__row'>
                        <div className='form__col'>
                            <Input
                                label='Leitarorð'
                                name="term"
                                id="term"
                                value={values.term}
                                disabled={disabled}
                            />
                        </div>
                        <div className='form__col datepicker-col'>
                            <DatePickerField
                                name="dateFrom"
                                value={values.dateFrom}
                                label='Dags.Frá'
                                maxDate={new Date()}
                                disabled={disabled}
                            />
                            <DatePickerField
                                name="dateTo"
                                value={values.dateTo}
                                label='Dags.Til'
                                maxDate={new Date()}
                                disabled={disabled}
                            />
                        </div>
                        <div className='form__col actions'>
                            <div className='form__actions'>
                                <Button
                                    showLoader={loading}
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Leita
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}
