// Package imports:
import React, { useMemo } from "react";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Modal from "../../ui-elements/Modal/Modal";
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import Input from "../../ui-elements/Input/Formik/Input";
import Checkbox from "../../ui-elements/Input/Formik/Checkbox";

type BoolType = 'bonds' | 'addresses' | 'history' | 'owners';
interface IProps {
    show: boolean,
    close(): void,
    submit(reference: string, bonds: boolean): void,
    dataSourceText: string,
    customText?: string | JSX.Element,
    customHeader?: string,
    type: BoolType,
    initialReference?: string,
    initialBoolValue?: boolean
};

interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    reference: string,
    boolValue: boolean
}

const SearchConfirmModalWithReferenceAndBool: React.FC<IProps> = ({
    show,
    close,
    submit,
    dataSourceText,
    customText,
    customHeader,
    type,
    initialReference = '',
    initialBoolValue = false
}) => {
    const checkboxLabel = useMemo(() => {
        switch (type) {
            case 'bonds':
                return "Kaupa með veðböndum";
            case 'addresses':
                return "Kaupa með heimilisföngum";
            case 'history':
                return 'Kaupa með eignasögu';
            case 'owners':
                return 'Kaupa með raunverulegum eigendum';
        }
    }, [type])

    const handleSubmit = (values: IFormValues) => {
        if (show) {
            submit(values.reference, values.boolValue);
        }
    }

    const handleCancel = () => {
        if (show) {
            close();
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => {
        return <Form>
            <div className="form__section">
                <div className="form__body">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="reference"
                                id="reference"
                                value={values.reference}
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__col">
                            <Checkbox
                                label={checkboxLabel}
                                name="boolValue"
                                id="boolValue"
                                value={values.boolValue ? 'true' : 'false'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form__actions">
                <Button
                    buttonType='secondary'
                    type='reset'
                >
                    Hætta við
                </Button>
                <Button
                    type="submit"
                > 
                    Halda áfram
                </Button>
            </div>
        </Form>
    }

    return (
        <Modal
            className="KCL_search-confirm-modal"
            show={show}
            onHide={close}
            header={customHeader ?? `Kaupa gögn úr ${dataSourceText}`}
            body={
                <>
                    <div className="infoSection">
                        {(customText)
                            ? customText
                            : (
                                <p className="paragraph">
                                    Þú ert að fara að kaupa gögn úr {dataSourceText}. Gögn eru samkvæmt
                                    <Link className='VerdskraLink' linkSize='18' url="/Verdskra#Gagnaleit">verðskrá Keldunnar</Link>.
                                </p>
                            )
                        }
                        <p className="paragraph">Þú getur sett tilvísun á reikninginn hér fyrir neðan:</p>
                    </div>
                    <Formik
                        initialValues={{
                            reference: initialReference,
                            boolValue: initialBoolValue
                        }}
                        validationSchema={Yup.object({
                            reference: Yup.string(),
                            boolValue: Yup.boolean()
                        })}
                        onSubmit={handleSubmit}
                        onReset={handleCancel}
                        component={form}
                    />
                </>
            }
        />
    );
}

export default SearchConfirmModalWithReferenceAndBool;