// Package imports:
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
// Component imports:
import Tabs from '../../ui-elements/Tabs/Tabs';
import Askriftarleid from './Askriftarleid/Askriftarleid';
import Greidsluupplysingar from './Greidsluupplysingar/Greidsluupplysingar';
import Notendur from './Notendur/Notendur';
import Vedbond from './Vedbond/Vedbond';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Lykilord from './Lykilord/Lykilord';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { isCompany } from '../../services/utils';
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { MySettingsViewModel } from '../../types/MyKeldaTypes';
import { Fetched } from '../../types/Types';

const Stillingar: React.FC = () => {
	const [ initialTab, setInitialTab ] = useState<number | undefined>(0);
	const [model, setModel] = useState<Fetched<MySettingsViewModel>>(null);

	const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/MyKelda/SettingsData`;
            const response = await fetch(url);
            if (response.ok) {
				if (response.redirected) {
                    window.location.href = response.url;
                } else {
					const body : MySettingsViewModel = await response.json();
					setModel(body);
				}
            } else {
                setModel(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setModel(new Error(ErrorMessages.NetworkError));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

	const displayTabs = () => {
		if (model instanceof Error) {
			return <Alert type='error' headText={model.message} />;
		}
		// Customer is subscriber
		if (model?.settingsResponse?.customerPlan === 1) {
			// Customer is company, and user is admin -> show Vedbond tab
			if (model.settingsResponse.customerSsnLegit && isCompany(model.settingsResponse.customerSsn) && model.isAdmin) {
				return <Tabs
					tabs={[{
						id: "Askriftarleid",
						label: "Áskriftarleið",
						component: <Askriftarleid model={model} setInitialTab={setInitialTab} />
					}, {
						id: "Greidsluupplysingar",
						label:"Greiðsluupplýsingar",
						component: <Greidsluupplysingar model={model} />
					}, {
						id: "Lykilord",
						label:"Lykilorð",
						component: <Lykilord />
					}, {
						id: "Notendur",
						label:"Notendur",
						component: <Notendur isAdmin={model?.isAdmin} bondsPossible={model.settingsResponse.customerBonds ?? false} />
					}, {
						id: "Vedbond",
						label:"Veðbönd",
						component: <Vedbond model={model} />
					}]}
					tabsSize="lg"
				/>
			}
			// Customer is not company, or user is not admin
			return <Tabs
				tabs={[{
					id: "Askriftarleid",
					label: "Áskriftarleið",
					component: <Askriftarleid model={model} />
				}, {
					id: "Greidsluupplysingar",
					label:"Greiðsluupplýsingar",
					component: <Greidsluupplysingar model={model} />
				}, {
					id: "Lykilord",
					label:"Lykilorð",
					component: <Lykilord />
				}, {
					id: "Notendur",
					label:"Notendur",
					component: <Notendur isAdmin={model?.isAdmin} />
				}]}
				tabsSize="lg"
			/>
		}
		// Customer is not subscriber
		return <Tabs
			clearRedirect={() => setInitialTab(undefined)}
			redirectToTab={initialTab}
			tabs={[{
				id: "Askriftarleid",
				label: "Áskriftarleið",
				component: <Askriftarleid model={model} setInitialTab={setInitialTab} />
			}, {
				id: "Greidsluupplysingar",
				label:"Greiðsluupplýsingar",
				component: <Greidsluupplysingar model={model} />
			}, {
				id: "Lykilord",
				label:"Lykilorð",
				component: <Lykilord />
			}]}
			tabsSize="lg"
		/>
	}

	return (
		<div className="main KCL__Stillingar">
			<div className='section__body'>
				<div className='shell'>
					<div className='row'>
						<div className='col-sm-12'>
							<HeadSection
								title='Stillingar'
								showRecentReports
							/>
						</div>
					</div>
					{displayTabs()}
				</div>
			</div>
		</div>
	)
}

export default Stillingar;