// Package imports:
import React, { useContext, useEffect, useState } from "react";
// Component imports:
import Button from '../../ui-elements/Button/Button';
import Tabs from '../../ui-elements/Tabs/Tabs';
import GemmaqContainer from './GemmaqContainer/GemmaqContainer';
import HeadSection from "../../ui-elements/HeadSection/HeadSection";
import GemmaqScale from "./GemmaqScale";
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const Gemmaq: React.FC = () => {
    const accessToken = useContext(AccessTokenContext);
    const [defaultProps, setDefaultProps] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: 60 * 60 * 1000
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    return (
        <div className="main KCL_gemmaq-page">
            <div className="main__inner-fluid">
                <div className="mini_shell">
                    <HeadSection
                        title='GEMMAQ kynjakvarði'
                        summary={
                            <p>GEMMAQ kynjakvarðinn veitir upplýsingar um kynjahlutföll í leiðtogastörfum fyrirtækja á íslenskum markaði, skráð og óskráð, eins og staðan er hverju sinni.</p>
                        }
                        description={
                            <p>Einkunnir eru gefnar á skalanum 0-10, þar sem 10 er hæsta einkunnin, miðað við hlutfall kvenna í framkvæmdastjórn og stjórn félagsins. Kvarðinn tekur til greina bæði kyn forstjóra og stjórnarformanns - sem reiknast jafnframt inn í GEMMAQ einkunn félags þegar kona gegnir stöðunni.</p>
                        }
                        actions={
                            <>
                                <Button
                                    size='lg'
                                    buttonType='secondary'
                                    anchorProps={{ href: 'https://www.gemmaq.io/', target: '_blank' }}
                                >
                                    Vefur GEMMAQ Ratings
                                </Button>
                                <Button
                                    size='lg'
                                    buttonType='primary'
                                    anchorProps={{ href: '/Markadir/Gemmaq-Pontun' }}
                                >
                                    GEMMAQ notkunarleyfi
                                </Button>  
                            </>
                        }
                        rightSide={
                            <GemmaqScale />
                        }
                        hasAds='main'
                    />
                </div>
                <div className="section__body">
                    <Tabs 
                        tabs={[{
                            id: "a-o",
                            label: "A-Ö",
                            component: <GemmaqContainer
                                {...defaultProps}
                                category='all'
                            />
                        }, {
                            id: "Útgefendur-skuldabréfa",
                            label: "Útgefendur skuldabréfa",
                            component: <GemmaqContainer
                                {...defaultProps}
                                category='XICE-BOND-ISSUERS'
                            />
                        }, {
                            id: "Skráð -hlutabréf",
                            label: "Skráð hlutabréf",
                            component: <GemmaqContainer
                                {...defaultProps}
                                category='XICE'
                            />
                        }, {
                            id: "Óskráð-félög",
                            label: "Óskráð félög",
                            component: <>
                                <div className="publishing-message">
                                    <h4>Viltu að þitt fyrirtæki birtist á þessum lista?</h4>
                                    <Button
                                        size='lg'
                                        buttonType='primary'
                                        anchorProps={{ href: '/Markadir/Gemmaq-Birting' }}
                                    >
                                        Panta birtingu
                                    </Button>
                                </div>
                                <GemmaqContainer
                                    {...defaultProps}
                                    category='OTC'
                                />
                            </>
                        }, {
                            id: "Topp-10",
                            label: "Topp 10",
                            component: <GemmaqContainer
                                {...defaultProps}
                                category='top10'
                            />
                        }, {
                            id: "Neðstu-10",
                            label: "Neðstu 10",
                            component: <GemmaqContainer
                                {...defaultProps}
                                category='bottom10'
                            />
                        }]}
                        tabsSize='lg'
                        grayAndShell
                    />
                </div>
            </div>
        </div>
    )
}

export default Gemmaq;