// Package imports:
import React from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import Link from "../../../ui-elements/Link/Link";
import Checkbox from "../../../ui-elements/Input/Formik/Checkbox";
import ExampleReportButton from "../../ExampleReportButton/ExampleReportButton";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiContact } from "../../../types/KeldanTypes";

interface IProps {
    isAuthenticated: boolean,
    bondsAllowed: boolean
}

const VehicleSearch: React.FC<IProps> = ({
    isAuthenticated,
    bondsAllowed
}) => {
    const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(async (values: IFormValues, modalReference, modalBonds) => {
        try {
            const requestQuery = new URLSearchParams({
                permanent: values.fastanr,
                registration: values.skraningarnr,
                vin: values.verksmidjunr,
                reference: modalReference,
                bonds: modalBonds.toString()
            });
            const url = `${GET_KELDAN_API_URL()}/Vehicle/VehicleSearch`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IKeldanApiContact = await response.json();
                    const { message } = responseBody;
                    
                    if (message === ErrorMessages.NoCard) {
                        resetState('no card');
                    } else if (message) {
                        resetState({
                            type: 'alert',
                            headText: message
                        });
                    } else {
                        resetState('bad request')
                    }
                }
            } else {
                resetState('response not ok')
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error')
        }
    }, 'ökutækjaskrá');

    const displayForm = (status: 'enabled' | 'disabled') => {
        return (
            <Formik
                initialValues={{
                    fastanr: '',
                    skraningarnr: '',
                    verksmidjunr: '',
                    tilvisun: '',
                    bonds: false
                }}
                validationSchema={Yup.object({
                    fastanr: Yup.string(),
                    skraningarnr: Yup.string(),
                    verksmidjunr: Yup.string(),
                    tilvisun: Yup.string(),
                    bonds: Yup.boolean()
                })}
                onSubmit={(values) => tryPurchaseItem(
                    values,
                    bondsAllowed
                        ? 'confirm-modal-with-reference-and-bonds'
                        : 'confirm-modal-with-reference',
                    values.tilvisun,
                    values.bonds
                )}
                component={(props: { values: IFormValues }) => (
                    <SubFormComponent
                        bondsAllowed={bondsAllowed}
                        values={props.values}
                        loading={(tryToBuyItem !== null)}
                        disabled={status === 'disabled'}
                    />
                )}
            />
        );
    }

    return (
        <div className="Okutaekjaskra">
            <div className="section__body">
                <div className='KCL_vehicle-search-form'>
                    <h3>
                        {!isAuthenticated && <LockIcon size="lg" /> }
                        <span>Uppfletting í Ökutækjaskrá</span>
                    </h3>
                    <div className='search-form-description paragraph'>
                        Með því að slá inn fasta-, skráningar- eða verksmiðjunúmer er hægt að kaupa upplýsingar um ökutæki. <Link linkSize="18" href="/Verdskra#Gagnaleit">Sjá verðskrá.</Link>
                    </div>
                    {isAuthenticated
                        ? displayForm('enabled')
                        : <Tooltip
                            tooltip={
                                <span>
                                    Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                                </span>
                            }
                            text={displayForm('disabled')}
                        />
                    }
                    <ExampleReportButton size='lg' reportType='vehicle' />
                </div>
            </div>
            <ModalsAndToasts />
        </div>
    );
}

export default VehicleSearch;

interface IFormValues {
    fastanr: string,
    skraningarnr: string,
    verksmidjunr: string,
    tilvisun: string,
    bonds: boolean
}

interface ISubFormProps {
    bondsAllowed: boolean,
    values: IFormValues,
    loading: boolean,
    disabled: boolean
}

const SubFormComponent: React.FC<ISubFormProps> = ({
    bondsAllowed,
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Fastanúmer"
                                name="fastanr"
                                id="fastanr"
                                value={values.fastanr}
                                disabled={(values.skraningarnr !== '' || values.verksmidjunr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Skráningarnúmer"
                                name="skraningarnr"
                                id="skraningarnr"
                                value={values.skraningarnr}
                                disabled={(values.fastanr !== '' || values.verksmidjunr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Verksmiðjunúmer"
                                name="verksmidjunr"
                                id="verksmidjunr"
                                value={values.verksmidjunr}
                                disabled={(values.fastanr !== '' || values.skraningarnr !== '') || disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="tilvisun"
                                id="tilvisun"
                                value={values.tilvisun}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="buy"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Kaupa
                                </Button>
                            </div>
                        </div>
                    </div>
                    {bondsAllowed &&
                        <div className="form__row checkbox-row">
                            <div className="form__col">
                                <Checkbox
                                    label="með veðböndum"
                                    name="bonds"
                                    id="bonds"
                                    value={values.bonds.toString()}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Form>
    );
}

