// Package imports:
import React, { useMemo } from 'react';
import ISLocale from 'date-fns/locale/is';
import { format } from 'date-fns';
import { SeriesOptionsType } from 'highcharts';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DefaultChart from '../DefaultChart/DefaultChart';
// Service imports:
import { formatNumber, getAPItoday } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiHistoryTimeseriesShortDATA, IApiLmdSpecialFundsChart } from '../../../types/MarketTypes';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};

interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};


interface IOwnProps {
    symbol?: string,
    title?: string,
    height?: number
};

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const SpecialFundsChart: React.FC<Props> = ({
    symbol,
    title,
    height = 500,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [apiDataFrjalsi1] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/frjalsi_1/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [apiDataFrjalsi2] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/frjalsi_2/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [apiDataFrjalsi3] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/frjalsi_3/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [apiDataFrjalsiA] = useApiLmdData<IApiHistoryTimeseriesShortDATA>(
        `/v1/market_data/v1/funds/frjalsi_a/history_timeseries/?from_date=1972-02-26&to_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );

    const handleChartData = (body: IApiHistoryTimeseriesShortDATA) => {
        let dataToReturn: IApiLmdSpecialFundsChart[] = [];
        for(let datum of body.data){
            dataToReturn.push({
                value_date: datum[0],
                lastprice: datum[1],
            })
        }
        return dataToReturn;
    }
    
    const getFrjalsi1Data = () => {
        const chartDataInfoFrjalsi1: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataFrjalsi1.data !== null && !(apiDataFrjalsi1.error instanceof Error)) {
            chartDataInfoFrjalsi1.name = 'Frjálsi 1';
            for (let datum of handleChartData(apiDataFrjalsi1.data)) {
                chartDataInfoFrjalsi1.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoFrjalsi1;
    }
    const getFrjalsi2Data = () => {
        const chartDataInfoFrjalsi2: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataFrjalsi2.data !== null && !(apiDataFrjalsi2.error instanceof Error)) {
            chartDataInfoFrjalsi2.name = 'Frjálsi 2';
            for (let datum of handleChartData(apiDataFrjalsi2.data)) {
                chartDataInfoFrjalsi2.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoFrjalsi2;
    }
    const getFrjalsi3Data = () => {
        const chartDataInfoFrjalsi3: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataFrjalsi3.data !== null && !(apiDataFrjalsi3.error instanceof Error)) {
            chartDataInfoFrjalsi3.name = 'Frjálsi 3';
            for (let datum of handleChartData(apiDataFrjalsi3.data)) {
                chartDataInfoFrjalsi3.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoFrjalsi3;
    }
    const getFrjalsiAData = () => {
        const chartDataInfoFrjalsiA: IChartDataInfo = {
            name: '',
            data: []
        }
        if (apiDataFrjalsiA.data !== null && !(apiDataFrjalsiA.error instanceof Error)) {
            chartDataInfoFrjalsiA.name = 'Frjálsi Áhætta';
            for (let datum of handleChartData(apiDataFrjalsiA.data)) {
                chartDataInfoFrjalsiA.data.push({
                    x: datum.value_date ?? undefined,
                    y: datum.lastprice ?? undefined
                });
            }
        }
        return chartDataInfoFrjalsiA;
    }
    const series: SeriesOptionsType[] = useMemo(() => {
        //FRJÁLSI 1 
        const chartDataInfoFrjalsi1 = getFrjalsi1Data();
        //FRJÁLSI 2
        const chartDataInfoFrjalsi2 = getFrjalsi2Data();
        //FRJÁLSI 3
        const chartDataInfoFrjalsi3 = getFrjalsi3Data();
        //FRJÁLSI ÁHÆTTA
        const chartDataInfoFrjalsiA = getFrjalsiAData();
        return [{
            type: 'line',
            id: 'A',
            name: 'Frjálsi 1',
            data: chartDataInfoFrjalsi1.data,
            color: '#4569EE'
        }, {
            type: 'line',
            id: 'B',
            name: 'Frjálsi 2',
            data: chartDataInfoFrjalsi2.data,
            color: '#FF7697',
        }, {
            type: 'line',
            id: 'C',
            name: 'Frjálsi 3',
            data: chartDataInfoFrjalsi3.data,
            color: '#48A85D',
        }, {
            type: 'line',
            id: 'D',
            name: 'Frjálsi Áhætta',
            data: chartDataInfoFrjalsiA.data,
            color: '#6CD3FF',
        }]
    }, [ apiDataFrjalsi1, apiDataFrjalsi2, apiDataFrjalsi3, apiDataFrjalsiA]);

    return (
        <DisplayBox
            title={title}
            className='KCL_chart'
            {...props}
        >
            <DefaultChart
                height={height}
                tooltipFormatter={
                    function() {
                        //filtera einungis það sem er visible af því ef það er notað points[0] crashar allt þegar eitthvað series er disable-að
                        return (`<div class="chart-tooltip">
                                    <p class="tooltip-date">${format(this.x, 'EEEE, dd. MMM yyyy', {locale: ISLocale})}</p>
                                    ${this.points?.filter(x => x.point.visible === true).map(x => (
                                        `<p><span style="color: ${x.color}">${x.series.getName()}</span>: ${formatNumber(x.y, '-', 1)}</p>`
                                    ))}
                                </div>`).replace(/>,</g,'><')
                    }
                }
                yAxes={[{
                    title: {
                        text: 'Verð',
                        offset: 52,
                        x: 5
                    },
                    labels: {
                        x: -11,
                        y: 2
                    }
                }]}
                series={series}
                startRange={6}
            />
        </DisplayBox>
    );
}

export default SpecialFundsChart;