// Package imports:
import React, { useState } from "react";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import Bugsnag from "@bugsnag/js";
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Input from '../../../ui-elements/Input/Formik/Input';
import Radio from '../../../ui-elements/Radio/Radio';
import Checkbox from '../../../ui-elements/Input/Formik/Checkbox';
import Alert from "../../../ui-elements/Alert/Alert";
import { IEditUser } from "./Notendur";
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
import { isPerson } from "../../../services/utils";

interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    fullname: string,
    email: string,
    active: string,
    bonds: boolean,
    category: string,
    id: string | null,
    username: string,
    ssn: string
}

interface IMyKeldaSavedResponse {
    saved: boolean,
    errorMsg?: string
}

interface IProps {
    type: ModalType,
    editUserObject?: IEditUser,
    setContactResponse: (a: JSX.Element) => void,
    closeModal: () => void,
    bondsPossible?: boolean
}

type ModalType = 'edit' | 'new';

const NotendurForm: React.FC<IProps> = ({
    type,
    editUserObject,
    setContactResponse,
    closeModal,
    bondsPossible = false
}) => {
    const [activeStatus, setActiveStatus] = useState(editUserObject && !(editUserObject.active) ? '0' : '1');
    const [ userID ] = useState(editUserObject?.id ?? null);
    const [ kennitala ] = useState(editUserObject?.ssn);

    const handleSubmit = async (values: IFormValues) => {
        let requestQuery;
        try {
            setContactResponse(<></>);
            if (userID) {
                requestQuery = new URLSearchParams({
                    fullname: values.fullname,
                    email: values.email,
                    active: activeStatus,
                    bonds: values.bonds.toString(),
                    category: values.category,
                    id: userID,
                    ssn: values.ssn
                });
            } else {
                requestQuery = new URLSearchParams({
                    fullname: values.fullname,
                    email: values.email,
                    active: activeStatus,
                    bonds: values.bonds.toString(),
                    category: values.category,
                    ssn: values.ssn
                });
            }
            
            const url = `${GET_KELDAN_API_URL()}/MyKelda/Users`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            closeModal();
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IMyKeldaSavedResponse = await response.json();
                    if (responseBody.saved) {
                        setContactResponse(
                            <Alert
                                closeAlert={() => setContactResponse(<></>)}
                                withCloseIcon
                                type="success"
                                headText="Notandi vistaður"
                            />
                        );
                    } else {
                        setContactResponse(
                            <Alert
                                closeAlert={() => setContactResponse(<></>)}
                                withCloseIcon
                                type="error"
                                headText={responseBody.errorMsg ?? ErrorMessages.OtherShort}
                            />
                        );
                    }
                }
            } else {
                setContactResponse(
                    <Alert
                        closeAlert={() => setContactResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={ErrorMessages.RequestFailed}
                    />
                );
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setContactResponse(
                <Alert
                    closeAlert={() => setContactResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText={ErrorMessages.NetworkError}
                />
            );
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => {
        return <Form>
            {(type === 'edit')
                ? <h3>Breyta notanda</h3>
                : <h3>Nýr notandi</h3>
            }
            <div className="form__body">
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label="Nafn"
                            name="fullname"
                            id="fullname"
                            value={values.fullname}
                        />
                    </div>
                    <div className="form__col">
                        <Input
                            label="Netfang"
                            name="email"
                            id="email"
                            disabled={type === 'edit'}
                            value={values.email}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label="Kennitala"
                            name="ssn"
                            id="ssn"
                            value={values.ssn}
                            disabled={isPerson(kennitala)}
                        />
                    </div>
                    {type === 'edit' &&
                        <div className="form__col">
                            <Input
                                disabled
                                label="Notandanafn"
                                name="username"
                                id="username"
                                value={values.username}
                            />
                        </div>
                    }
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label="Deild / svið"
                            name="category"
                            id="category"
                            value={values.category}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <div className='radios-group'>
                            <span>Staða:</span>
                            <ul>
                                <li>
                                    <Radio
                                        id="active"
                                        name="active"
                                        value="1"
                                        defaultValue={activeStatus}
                                        onChange={(e) => setActiveStatus(e.target.value)}
                                    >
                                        Virkur
                                    </Radio>
                                </li>
                                <li>
                                    <Radio
                                        id="notActive"
                                        name="active"
                                        value='0'
                                        defaultValue={activeStatus ?? '1'}
                                        onChange={(e) => setActiveStatus(e.target.value)}
                                    >
                                        Óvirkur
                                    </Radio>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {bondsPossible &&
                    <div className="form__row">
                        <div className="form__col">
                            <Checkbox 
                                label="Aðgangur að veðböndum"
                                name="bonds"
                                id="bonds"
                                className="field-input"
                                value={values.bonds ? 'true' : 'false'}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className="form__actions text__center">
                <Button type="submit" buttonType="primary" size="lg">Vista</Button>
            </div>
        </Form>
    }
    
    return(
        <div className="KCL_notendur-form modal-form">
            <Formik
                initialValues={{
                    fullname: editUserObject?.name ?? '',
                    email: editUserObject?.email ?? '',
                    active: activeStatus ?? '0',
                    bonds: editUserObject?.bonds ?? true,
                    category: editUserObject?.category ?? '',
                    id: userID,
                    username: editUserObject?.username ?? editUserObject?.email ?? '',
                    ssn: editUserObject?.ssn ?? ''
                }}
                validationSchema={Yup.object({
                    fullname: Yup.string()
                        .required('Nafn má ekki vanta'),
                    email:  Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                    active: Yup.string(),
                    bonds: Yup.boolean(),
                    category: Yup.string(),
                    ssn: Yup.string()
                        .required('Kennitala verður að fylgja')
                        .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                        .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                        .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                        .test('ssn-test', 'Ógild einstaklings kennitala', (value) => isPerson(value))
                })}
                onSubmit={handleSubmit}
                component={form}
            />
        </div>
    )
}

export default NotendurForm;