// Package imports:
import React, { useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import Link from "../../../ui-elements/Link/Link";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import DisplayBox from "../../../ui-elements/DisplayBox/DisplayBox";
import Table from "../../../ui-elements/Table/Table";
import Checkbox from "../../../ui-elements/Input/Formik/Checkbox";
import ExampleReportButton from "../../ExampleReportButton/ExampleReportButton";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
import SearchResults from "../../../ui-elements/SearchResults/SearchResults";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiResponse } from "../../../types/KeldanTypes";
import { IPropertySearchResult, IPropertySearchViewModel } from "../../../types/PropertyTypes";

interface IBuyFasteignaSkra {
    whereClick: 'form' | 'list'
    fastanr?: string,
    heitisnr?: string
}

const Fasteignaskra: React.FC<IPropertySearchViewModel> = ({
    isAuthenticated,
    bondsAllowed = false
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<IPropertySearchResult>();
    const [isSearchFormLoading, setIsSearchFormLoading] = useState(false);

    const searchResultsRef = useRef<HTMLDivElement | null>(null);

    const { tryPurchaseItem, tryToBuyItem, resetState, setToastError, ModalsAndToasts } = useBuyingProcess(async (values: IBuyFasteignaSkra, modalReference, modalBonds) => {
        try {
            const { fastanr, heitisnr } = values;
            let url = '';
            let query = new URLSearchParams();
            if (fastanr) {
                url = `${GET_KELDAN_API_URL()}/Property/PropertySearchFastanr`;
                query = new URLSearchParams({
                    term: fastanr,
                    reference: modalReference,
                    bonds: `${modalBonds}`
                })
            } else if (heitisnr) {
                url = `${GET_KELDAN_API_URL()}/Property/PropertySearchHeiti`;
                query = new URLSearchParams({
                    term: heitisnr,
                    reference: modalReference
                })
            }

            const response = await fetch(`${url}?${query.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            // Done after the request for better user experience.
            if (heitisnr) setSearchTerm(heitisnr);
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<IPropertySearchResult> = await response.json();
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card')
                } else if (message) {
                    resetState({
                        type: 'alert',
                        headText: message
                    });
                } else {
                    resetState('bad request');
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'fasteignaskrá');

    const handleSearch = async (values: ISearchFormValues) => {
        try {
            const { heiti } = values;
            const requestQuery = new URLSearchParams({
                term: heiti
            });
            const url = `${GET_KELDAN_API_URL()}/Property/PropertySearchAll`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            // Done after the request for better user experience.
            setSearchTerm(heiti);
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IKeldanApiResponse<IPropertySearchResult> = await response.json();
                    const { message, result } = responseBody;
                    if (result) {
                        setSearchResults(result);
                        searchResultsRef.current?.scrollIntoView();
                    } else if (message === ErrorMessages.NoCard) {
                        setToastError('no card');
                    } else if (message) {
                        setToastError({
                            type: 'alert',
                            headText: message
                        })
                    } else {
                        setToastError('bad request');
                    }
                }
            } else {
                setToastError('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setToastError('network error');
        }
        // Reset all loading states.
        setIsSearchFormLoading(false)
    }

    const displayForms = (status: 'enabled' | 'disabled') => {
        return <div>
            <Formik
                initialValues={{
                    fastanr: '',
                    tilvisun: '',
                    bonds: false
                }}
                validationSchema={Yup.object({
                    fastanr: Yup.string().required('Fastanúmer má ekki vera tómt'),
                    tilvisun: Yup.string(),
                    bonds: Yup.boolean()
                })}
                onSubmit={({ fastanr, bonds, tilvisun }) => tryPurchaseItem(
                    { fastanr, whereClick: 'form' },
                    bondsAllowed
                        ? 'confirm-modal-with-reference-and-bonds'
                        : 'confirm-modal-with-reference',
                    tilvisun,
                    bonds
                )}
                component={(props: { values: IPurchasingFormValues }) => (
                    <PurchasingSubFormComponent
                        bondsAllowed={bondsAllowed}
                        loading={(
                            tryToBuyItem !== null
                            && tryToBuyItem.whereClick === 'form'
                        )}
                        values={props.values}
                        disabled={status === 'disabled'}
                    />
                )}
            />
            <p className='paragraph'>Einnig er hægt að leita eftir fasteign</p>
            <Formik
                initialValues={{
                    heiti: ''
                }}
                validationSchema={Yup.object({
                    heiti: Yup.string().required('Leit má ekki vera tóm')
                })}
                onSubmit={(values) => {
                    setIsSearchFormLoading(true);
                    handleSearch(values)
                }}
                component={(props: { values: ISearchFormValues }) => (
                    <SearchSubFormComponent
                        loading={isSearchFormLoading}
                        values={props.values}
                        disabled={status === 'disabled'}
                    />
                )}
            />
        </div>
    }

    return (
        <div className='KCL_property-search-form'>
            <div className="shell">
                <h3>
                    {!isAuthenticated && <LockIcon size='lg' />}
                    <span>Uppfletting í Fasteignaskrá Íslands</span>
                </h3>
                <div className='property-form-description paragraph'>
                    Með því að slá inn fastanúmer er hægt að kaupa skýrslu frá Fasteignaskrá. <Link linkSize="18" url="/Verdskra#Gagnaleit">Sjá verðskrá.</Link>
                </div>
                {isAuthenticated
                    ? displayForms('enabled')
                    : <Tooltip
                        tooltip={
                            <span>
                                Þú þarft að skrá þig inn og vera með skráð kreditkort til að nýta þessa þjónustu.
                            </span>
                        }
                        text={displayForms('disabled')}
                    />
                }
                <ExampleReportButton size='lg' reportType='property' />
            </div>
            <SearchResults
                title={`Leitarniðurstöður fyrir ${searchTerm}`}
                show={searchResults !== undefined}
                closeResults={() => setSearchResults(undefined)}
                ref={searchResultsRef}
                component={
                    searchResults?.response?.addresses
                        ? <DisplayBox>
                            <Table
                                tableSize="lg"
                                data={searchResults.response.addresses}
                                columns={[{
                                    title: 'Heitisnúmer',
                                    renderCell: ({address: {name_number}}) => name_number,
                                    textAlign: 'left'
                                }, {
                                    title: 'Heiti',
                                    renderCell: ({address: {street}}) => street,
                                    textAlign: 'left'
                                }, {
                                    title: 'Húsnúmer',
                                    renderCell: ({address: {house_number}}) => house_number,
                                    textAlign: 'left'
                                }, {
                                    title: 'Sveitarfélag',
                                    renderCell: ({address: {county}}) => county,
                                    textAlign: 'left'
                                }, {
                                    title: 'Byggð',
                                    renderCell: ({address: {city}}) => city,
                                    textAlign: 'left'
                                }, {
                                    title: 'Yfirlitsmynd',
                                    renderCell: ({ address: {name_number} }) => (
                                        <Button
                                            buttonType="buy"
                                            size='sm'
                                            showLoader={(
                                                tryToBuyItem !== null
                                                && tryToBuyItem.heitisnr === name_number
                                                && tryToBuyItem.whereClick === 'list'
                                            )}
                                            onClick={() => name_number && tryPurchaseItem({
                                                heitisnr: name_number,
                                                whereClick: 'list'
                                            }, 'confirm-modal-with-reference')}
                                        >
                                            Kaupa
                                        </Button>
                                    )
                                }]}
                            />
                        </DisplayBox>
                        : <Alert type='info' headText="Engar niðurstöður fundust" />
                }
            />
            <ModalsAndToasts />
        </div>
    );
}

export default Fasteignaskra;

interface ISearchFormValues {
    heiti: string
}

interface ISearchSubFormProps {
    values: ISearchFormValues,
    loading: boolean,
    disabled: boolean
}

const SearchSubFormComponent: React.FC<ISearchSubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                placeholder="Leita eftir götuheiti, fastanúmeri, heitisnúmeri eða landnúmeri"
                                name="heiti"
                                id="heiti"
                                value={values.heiti}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="primary"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Leita
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}


interface IPurchasingFormValues {
    fastanr: string,
    tilvisun: string,
    bonds: boolean
}

interface IPurchasingSubFormProps {
    bondsAllowed: boolean,
    values: IPurchasingFormValues,
    loading: boolean,
    disabled: boolean
}

const PurchasingSubFormComponent: React.FC<IPurchasingSubFormProps> = ({
    bondsAllowed,
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Fastanúmer eignar"
                                name="fastanr"
                                id="fastanr"
                                value={values.fastanr}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="tilvisun"
                                id="tilvisun"
                                value={values.tilvisun}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="buy"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Kaupa
                                </Button>
                            </div>
                        </div>
                    </div>
                    {bondsAllowed &&
                        <div className="form__row checkbox-row">
                            <div className="form__col">
                                <Checkbox
                                    label="með veðböndum"
                                    name="bonds"
                                    id="bonds"
                                    value={values.bonds.toString()}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Form>
    );
}

