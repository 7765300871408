// Package imports:
import React, { useEffect, useState } from "react";
//@ts-ignore
import Columns from 'react-columns';
import Bugsnag from "@bugsnag/js";
import { format } from 'date-fns';
import ISLocale from 'date-fns/locale/is';
// Component imports:
import Modal from "../../../ui-elements/Modal/Modal";
import Radio from '../../../ui-elements/Radio/Radio';
import Link from "../../../ui-elements/Link/Link";
import Button from "../../../ui-elements/Button/Button";
import Alert from "../../../ui-elements/Alert/Alert";
import Input from "../../../ui-elements/Input/Input";
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from "../../../services/errorMessages";
import { getSubscriptionPrice } from "../../../services/utils";
// Type imports:
import { IDiscountCodeResponse, IMyKeldaSettingsResponse, MySettingsViewModel } from '../../../types/MyKeldaTypes';
import CheckList from "../../../ui-elements/Lists/CheckList";

const queries = [{
    columns: 2,
    query: 'min-width: 1024px'
}];

interface IProps {
    model: MySettingsViewModel | null,
    setInitialTab?: (a: number) => void
}

const Askriftarleid: React.FC<IProps> = ({
    model,
    setInitialTab
}) => {
    useEffect(()=>{},[model])
    const [subscription, setSubscription] = useState<string>(model?.settingsResponse?.customerPlan?.toString() ?? '');
    const [discountCode, setDiscountCode] = useState<string>('');
    const [contactResponse, setContactResponse] = useState<JSX.Element>(<></>);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [freeToPremiumModalOpen, setFreeToPremiumModalOpen] = useState(false);
    const [premiumToFreeModalOpen, setPremiumToFreeModalOpen] = useState(false);
    useEffect(() => { setSubscription(model?.settingsResponse?.customerPlan?.toString() ?? '') }, [model?.settingsResponse?.customerPlan]);

    const discountCodeValid = async () => {
        try {
            const requestQuery = new URLSearchParams({
                code: discountCode ?? ''
            });
            const url = '/MyKelda/CheckDiscountCodeValidity';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                setContactResponse(
                    <Alert
                        closeAlert={() => setContactResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={ErrorMessages.RequestFailed}
                    />
                );
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IDiscountCodeResponse = await response.json();
            if (responseBody.codeExists) {
                if (responseBody.codeValid) {
                    if (!responseBody.customerHasUsedCode) {
                        return true;
                    } else {
                        setContactResponse(<Alert
                            closeAlert={() => setContactResponse(<></>)}
                            withCloseIcon
                            type="error"
                            headText='Þú hefur nú þegar notað þennan afsláttarkóða'
                        />);
                        return false;
                    }
                } else {
                    setContactResponse(<Alert
                        closeAlert={() => setContactResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText='Kóði útrunninn'
                    />);
                    return false;
                }
            } else {
                setContactResponse(<Alert
                    closeAlert={() => setContactResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText='Rangur kóði'
                />);
                return false;
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setContactResponse(
                <Alert 
                    closeAlert={() => setContactResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText={ErrorMessages.NetworkError}
                />
            );
        }
    }

    const handleSubmit = async () => {
        //CLOSE BOTH MODALS IF OPEN
        if (freeToPremiumModalOpen) setFreeToPremiumModalOpen(false);
        if (premiumToFreeModalOpen) setPremiumToFreeModalOpen(false);
        try {
            setContactResponse(<></>);
            if (discountCode && !await discountCodeValid()) return;
            const requestQuery = new URLSearchParams({
                customerPlan: subscription,
                discountCode: discountCode ?? ''
            });
            const url = `${GET_KELDAN_API_URL()}/MyKelda/UpdateUserPlan`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                setContactResponse(
                    <Alert
                        closeAlert={() => setContactResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={ErrorMessages.RequestFailed}
                    />
                );
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IMyKeldaSettingsResponse = await response.json();
            if (responseBody.saved) {
                if (responseBody.discount) {
                    setContactResponse(
                        <Alert
                            closeAlert={() => setContactResponse(<></>)}
                            withCloseIcon
                            type="success"
                            headText="Velkomin í áskrift"
                            text={<>
                                Nú getur þú skráð nýja notendur inn í kerfið, sótt um aðgang að veðböndum og nýtt þér vöktun Keldunnar.
                                Kóði móttekinn.
                                Prufutímabil virkt til {responseBody.chargeDate && format(new Date(responseBody.chargeDate), 'dd.MM.yyyy', { locale: ISLocale })}.
                                Mánaðargjald verður ekki gjaldfært fyrr en prufutímabili lýkur.
                                Þú getur breytt áskriftinni í "Stillingar" á keldan.is í fría áskrift ef þú kýst að halda ekki áfram í mánaðarlegri áskrift.
                                Hafðu samband við <a href="mailto:info@keldan.is">info@keldan.is</a> ef þig vantar aðstoð.
                            </>}
                        />
                    );
                } else {
                    setContactResponse(
                        <Alert
                            closeAlert={() => setContactResponse(<></>)}
                            withCloseIcon
                            type="success"
                            headText='Velkomin í áskrift'
                            text="Nú getur þú skráð nýja notendur inn í kerfið, sótt um aðgang að veðböndum og nýtt þér vöktun Keldunnar."
                        />
                    );
                }
            } else {
                setContactResponse(
                    <Alert  
                        closeAlert={() => setContactResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={
                            responseBody.info ?? 
                            'Breytingar hafa ekki verið vistaðar'
                        }
                    />
                );
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setContactResponse(
                <Alert 
                    closeAlert={() => setContactResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText={ErrorMessages.NetworkError}
                />
            );
        }
    }

    const changeSubscription = () => {
        //CUSTOMERPLAN === 1 - PREMIUM
        //CUSTOMERPLAN === 3 - FREE

        if (subscription !== model?.settingsResponse?.customerPlan?.toString()) {
            //CHANGING SUBSCRIPTION FROM PREMIUM TO FREE
            if (subscription === '3' && model?.settingsResponse?.customerPlan === 1) {
                //SHOW MODAL onclick "you're going to lose all users modal"
                setPremiumToFreeModalOpen(true);
            } 
            // CHANGING SUBSCRIPTION FROM FREE TO PREMIUM
            // PAYMENT FOUND
            else if ((model?.card || model?.settingsResponse?.cardUsable) && model?.settingsResponse?.customerSsnLegit) {
                //DISPLAY DO YOU REAAAALLY WANT IT THO? MODAL
                setFreeToPremiumModalOpen(true);
            }
            //NO PAYMENT FOUND FOR USER
            else {
                //DISPLAY NO PAYMENT MODAL
                setPaymentModalOpen(true);
            }
        } else if (discountCode) {
            handleSubmit();
        }
    }

    const displayPriceCard = () => {
        switch (subscription) {
            case "1":
                return <div className="price-card">
                    <div className="price-card__type">Áskrift</div>
                    <div className="price-card__price">
                        <p>
                            <strong>{getSubscriptionPrice()}</strong>
                            kr. á mánuði + VSK
                        </p>
                    </div>
                    <div className="price-card__content">
                        <CheckList
                            items={[
                                'Aðgangur fyrir allt að 4 notendur',
                                'Aðgangur að öllum helstu skrám',
                                <>Afsláttur af skýrslum (<Link url="/Verdskra#Gagnaleit" linkSize="16">sjá verðskrá</Link>)</>,
                                'Gögn sýnileg á fyrirtækjasíðum',
                                'Innslegnir ársreikningar',
                                'Samanburðarskýrslur',
                                'Fyrirtæki geta sótt um aðgang að veðböndum'
                            ]}
                            size="sm"
                        />
                    </div>
                </div>
            case "3":
                return <div className="price-card">
                    <div className="price-card__type">Frítt</div>
                    <div className="price-card__price">
                        <p>
                            <strong>0</strong>
                            kr. á mánuði + VSK
                        </p>
                    </div>
                    <div className="price-card__content">
                        <CheckList
                            items={[
                                <>Aðgangur að öllum helstu skrám, keyptar skýrslur eru samkvæmt <Link url="/Verdskra#Gagnaleit" linkSize="16"> verðskrá</Link></>
                            ]}
                            size="sm"
                        />
                    </div>
                </div>
            default:
                return null
        }
    }

    return (
        <div className="Askriftarleid">
            <div>
                {contactResponse && (
                    <div className="form__row">
                        <div className="form__col">
                            {contactResponse}
                        </div>
                    </div>
                )}
            </div>
            <div className="section__body">
                <div className="left-section">
                    <Columns className="left-section__body" queries={queries}>
                        {/* COLUMN ONE */}
                        <div>
                            <h3>Tegund áskriftar</h3>
                            <div className='radios-group'>
                                <ul>
                                    <li>
                                        <Radio
                                            id="freeSubscription"
                                            name="subscription"
                                            value="3"
                                            defaultValue={subscription}
                                            onChange={(e) => setSubscription(e.target.value)}
                                            disabled={!model?.isAdmin}
                                        >
                                            Frí áskrift
                                        </Radio>
                                    </li>
                                    <li>
                                        <Radio
                                            id="subscriptionToFour"
                                            name="subscription"
                                            value="1"
                                            defaultValue={subscription}
                                            onChange={(e) => setSubscription(e.target.value)}
                                            disabled={!model?.isAdmin}
                                        >
                                            Áskrift
                                        </Radio>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* COLUMN TWO */}
                        <div>
                            {displayPriceCard()}
                        </div>

                        {/* COLUMN THREE */}
                        <div className="buttonAndLinkWrapper">
                            {/* DISCOUNT CODE */}
                            {subscription === '1'
                                ? <Input
                                    name="discountCode"
                                    id="discountCode"
                                    value={discountCode}
                                    label="Afsláttarkóði"
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                    disabled={!model?.isAdmin}
                                />
                                : null
                            }
                            {/* BUTTON */}
                            <div>
                                {
                                    //NOTANDI HEFUR ÝTT Á RADIO BUTTON SEM ER EKKI HANS ÁSKRIFT
                                    // -> BIRTA VISTA BREYTINGAR TAKKA 
                                    ((subscription !== '' && subscription !== model?.settingsResponse?.customerPlan?.toString()) || discountCode)
                                        ? 
                                            <Button onClick={changeSubscription}>
                                                Vista breytingar
                                            </Button>
                                        //NOTHING HAS HAPPENED
                                        //RETURN NOTHING
                                        : <></>
                                }
                            </div>
                            {/* NEED MORE USERS? */}
                            {
                                (subscription === '1') && model?.isAdmin
                                    ? <div className="more-users">
                                        <h5>Þarftu fimm eða fleiri notendur? </h5>
                                        <Link className="contactLink" url="/Hafa-Samband/Notendur" linkSize="16">Hafðu samband við Kelduna</Link>
                                    </div>
                                    : <></>
                            }
                        </div>
                    </Columns>
                </div>
                {/* USER INFO SECTION */}
                <div className="right-section">
                    <h3>Upplýsingar um notanda</h3>
                    <p className="paragraph-small">
                        <span>Nafn:</span>{model?.myUserInfo?.fullname ?? model?.myUserInfo?.username}
                    </p>
                    <p className="paragraph-small">
                        <span>Netfang:</span>{model?.myUserInfo?.email}
                    </p>
                    <p className="paragraph-small">
                        <span>Réttindi:</span>{model?.isAdmin ? 'Stjórnandi' : 'Almennur notandi'}
                    </p>
                </div>
            </div>

            {/* PREMIUM -> FREE SUBSCRIPTION MODAL */}
            <Modal
                className='KCL_askriftarleid-modal'
                show={premiumToFreeModalOpen}
                onHide={() => setPremiumToFreeModalOpen(false)}
                header='Breyta í fríáskrift'
                body={
                    <div className="alertBody">
                        <Alert type="alert" headText="Þú ert að fara að breyta úr mánaðarlegri áskrift yfir í fríáskrift." />
                        <div className="infoSection">
                            <p className="paragraph">Þú missir alla notendur sem skráðir eru í kerfið og einnig veðbönd séu þau virk.</p>
                            <p className="paragraph">Vilt þú halda áfram með þessa aðgerð?</p>
                        </div>
                        <div className="actions">
                            <Button onClick={() => setPremiumToFreeModalOpen(false)} buttonType='secondary'>Hætta við</Button>
                            <Button onClick={handleSubmit}>Halda áfram</Button>
                        </div>
                    </div>
                }
            />

            {/* FREE -> PREMIUM SUBSCRIPTION MODAL */}
            <Modal
                className='KCL_askriftarleid-modal'
                show={freeToPremiumModalOpen}
                onHide={() => setFreeToPremiumModalOpen(false)}
                header='Breyta í Áskrift - fyrir allt að 4 notendur'
                body={
                    <div className="alertBody">
                        {!discountCode && <Alert type="info" headText="Mánaðarleg áskriftargjöld verða dregin af skráðu kreditkorti."/>}
                        <div className="infoSection">
                            <p className="paragraph">Vilt þú halda áfram með þessa aðgerð?</p>
                        </div>
                        <div className="actions">
                            <Button onClick={() => setFreeToPremiumModalOpen(false)} buttonType='secondary'>Hætta við</Button>
                            <Button onClick={handleSubmit}>Halda áfram</Button>
                        </div>
                    </div>
                }
            />

            {/* NO WAY TO PAY MODAL */}
            <Modal
                className='KCL_askriftarleid-modal'
                show={paymentModalOpen}
                onHide={() => setPaymentModalOpen(false)}
                header='Breyta í Áskrift - fyrir allt að 4 notendur'
                body={
                    <div className="alertBody">
                        <Alert
                            type="error"
                            headText="Þú þarft að vera með skráðar greiðsluupplýsingar til að geta haldið áfram með þessa aðgerð."
                        />
                        <div className="infoSection">
                            <p className="paragraph">Til að geta breytt úr fríáskrift yfir í áskrift þurfa greiðsluupplýsingar að vera til staðar.</p>
                            <p className="paragraph">Farðu inn á síðuna Greiðsluupplýsingar til að skrá greiðanda og kortanúmer. Eftir það getur þú breytt í Áskrift.</p>
                        </div>
                        <div className="actions">
                            <Button
                                onClick={() => setPaymentModalOpen(false)}
                                buttonType='secondary'
                            >
                                Hætta við
                            </Button>
                            <Button 
                                onClick={() => {
                                    setPaymentModalOpen(false);
                                    if(setInitialTab) setInitialTab(1);
                                }}
                            >
                                Fara inn á Greiðsluupplýsingar
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default Askriftarleid;


