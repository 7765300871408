// Package imports:
import React from 'react';
// Component imports:
import ScrollSpy from '../../../ui-elements/ScrollSpy/ScrollSpy';
import ScrollSpySection from '../../../ui-elements/ScrollSpy/ScrollSpySection';
import Link from '../../../ui-elements/Link/Link';
// Service imports:
import { getSubscriptionPrice } from '../../../services/utils';

const Samningur: React.FC = () => {
    return (
        <div className='samningur'>
            <article className="article-overview">
                <div className="article__body">
                    <ScrollSpy
                        containerClass="article__content"
                        navClass="article__nav"
                        parentClass='samningur'
                        initialStep="lykiltolur-fyrirtaekja"
                    >
                        <ScrollSpySection className="article__section" id="lykiltolur-fyrirtaekja" label="Lykiltölur fyrirtækja">
                            <h2>Lykiltölur fyrirtækja</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>
                                        Í lykiltölum fyrirtækja getur þú skoðað heildaryfirlit yfir stærstu fyrirtæki Íslands. Nánari fjárhagsupplýsingar eru birtar á undirsíðum hvers félags ásamt þróun síðustu ára.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Upplýsingarnar eru úr ársreikningum eins og þeim var skilað til RSK nema annað sé tekið fram. Ef uppgjörsmynt er ekki ISK eru tölur í listanum reiknaðar á gengi SÍ þann 31/12 uppgjörsársins.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Keldan ber ekki ábyrgð á upplýsingunum. Ábendingar varðandi upplýsingarnar sendist á netfangið <div className='inline-link'><Link url="mailto:info@keldan.is" targetBlank linkSize='18'>info@keldan.is</Link></div>
                                    </span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="veitt-thjonusta" label="Veitt þjónusta">
                            <h2>Veitt þjónusta</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>
                                    Keldan veitir áskrifanda aðgang að upplýsingaþjónustu sinni á vefnum <div className='inline-link'><Link url="https://keldan.is" targetBlank linkSize='18'>www.keldan.is</Link></div>. Í upplýsingaþjónustunni felst
                                    m.a. aðgangur að upplýsingum úr Fasteignaskrá Íslands, ökutækjaskrá, þjóðskrá, hlutafélagaskrá Ríkisskattstjóra og Lögbirtingablaðinu.
                                    Nánari lýsingu er að finna í notkunarskilmálum Keldunnar.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Áskrifandi hefur heimild til að skrá allt að tíu notendur á sínum vegum í upplýsingakerfi Keldunnar.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Keldan geymir allar skýrslur sem áskrifandi sækir í gegnum upplýsingakerfi Keldunnar og eru skýrslurnar aðgengilegar áskrifanda á sama formi og
                                    þær voru sóttar á meðan áskrift varir.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Keldan veitir áskrifanda aðgang að reikningsyfirliti á vefnum <div className='inline-link'><Link url="https://keldan.is" targetBlank linkSize='18'>www.keldan.is</Link></div> fyrir alla notendur sem nota upplýsingakerfi
                                    Keldunnar á hans vegum.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                    Ef um tilfallandi kaup er að ræða er einungis veitt þjónusta samkvæmt a-lið þessa ákvæðis í samræmi við nánari ákvæði í notkunarskilmálum Keldunnar.
                                    </span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="greidslur" label="Greiðslur">
                            <h2>Greiðslur</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>Verðskrá er birt á vefnum <Link url="/Verdskra#Gagnaleit" targetBlank linkSize='18'>www.keldan.is</Link> og er efni hennar hverju sinni hluti af samningi þessum og notkunarskilmálum Keldunnar. Keldan áskilur sér rétt til breytinga á verðskrá og skulu breytingar tilkynntar áskrifanda, en ekki tilfallandi kaupanda, með tölvupósti á uppgefið netfang áskrifanda. Áskrifandi eða tilfallandi kaupandi, eftir því sem við á, hefur kynnt sér verðskrá Keldunnar og staðfestir þetta fyrirkomulag með undirskrift sinni.</span>
                                </li>

                                <li>
                                    <span>Áskrifandi greiðir mánaðarlegt áskriftargjald kr. {getSubscriptionPrice()} (að viðbættum virðisaukaskatti) sem greiðist eftirá fyrir hvern mánuð, eigi síðar en þann 10. næsta mánaðar á eftir. Áskriftargjald greiðist í fyrsta sinn fyrir næsta mánuð eftir að áskrifandi skráir sig. Ákvæði þessa stafliðar gildir ekki um tilfallandi áskrift.</span>
                                </li>

                                <li>
                                    <span>Áskrifandi eða tilfallandi kaupandi, eftir því sem við á, greiðir gjald fyrir upplýsingar sem sóttar eru í upplýsingakerfi Keldunnar skv. gildandi verðskrá hverju sinni. Gjald fyrir upplýsingar sem sóttar hafa verið er innheimt fyrir hvern mánuð að honum liðnum og greiðist eigi síðar en þann 10. næsta mánaðar.</span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="afhending-upplysinga" label="Fyrirvari um afhendingu upplýsinga">
                            <h2>Fyrirvari um afhendingu upplýsinga</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>Keldan sækir gögn sem áskrifandi eða tilfallandi kaupandi óskar eftir til ábyrgðaraðila opinberra skráa eins og lýst er nánar í notkunarskilmálum. Aðgangur að upplýsingum og þjónusta Keldunnar sem á honum byggist, s.s. vistun eldri skýrslna, byggir á reglum og framkvæmd ábyrgðaraðila hverju sinni. Keldan áskilur sér rétt til að breyta þjónustu sinni í samræmi við breyttar reglur og stjórnsýsluframkvæmd.</span>
                                </li>

                                <li>
                                    <span>Keldan ábyrgist ekki að gögn sem sótt eru í opinberar skrár séu rétt eða ber ábyrgð á túlkun áskrifenda eða tilfallandi kaupenda á þeim gögnum. Keldan ber ekki ábyrgð á töfum sem geta orðið á afhendingu gagna úr opinberum skrám og rekja má til starfsemi ábyrgðaraðila.</span>
                                </li>

                                <li>
                                    <span>Keldan verður ekki krafin um bætur vegna samnings þessa ef óviðráðanleg atvik svo sem vinnudeilur, náttúruhamfarir, styrjaldir, lagasetning eða aðrar gerðir opinberra aðila, rafmagnsleysi eða símbilun eða önnur þau atvik sem ekki eru sök samningsaðila eða í þeirra valdi að ráða við, koma í veg fyrir efndir samningsins.</span>
                                </li>

                                <li>
                                    <span>Keldan áskilur sér rétt til að rjúfa aðgang að upplýsingum um stundarsakir fyrirvaralaust og án tilkynningar, ef þörf krefur vegna viðhalds og endurbóta á kerfi Keldunnar, öryggis upplýsinga, o.þ.h.</span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="notkunarskilmalar" label="Notkunarskilmálar">
                            <h2>Notkunarskilmálar</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>Notkunarskilmálar Keldunnar, eins og þeir eru hverju sinni, eru hluti af samningi þessum.</span>
                                </li>

                                <li>
                                    <span>Keldan áskilur sér rétt til breytinga á notkunarskilmálum. Tilkynning um breytingar skal send áskrifanda í tölvupósti á uppgefið netfang áskrifanda áður en breytingar taka gildi. Tilfallandi kaupendum er ekki tilkynnt um breytingar. Áskrifandi eða tilfallandi kaupandi, eftir því sem við á, hefur kynnt sér notkunarskilmála Keldunnar og staðfestir þetta fyrirkomulag með undirskrift sinni.</span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="framsal-rettinda" label="Framsal réttinda og miðlun upplýsinga">
                            <h2>Framsal réttinda og miðlun upplýsinga</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>Áskrifanda eða tilfallandi kaupanda, eftir því sem við á, er ekki heimilt að framselja réttindi sín samkvæmt samningi þessum til þriðja aðila án samþykkis Keldunnar.</span>
                                </li>

                                <li>
                                    <span>Áskrifanda eða tilfallandi kaupanda, eftir því sem við á, er ekki heimilt að miðla til þriðja aðila upplýsingum sem sóttar eru gegnum upplýsingakerfi Keldunnar.</span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="uppsogn-samnings" label="Uppsögn samnings">
                            <h2>Uppsögn samnings</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>Áskrifandi getur sagt samningi þessum upp með því að senda tilkynningu í tölvupósti á netfangið <div className='inline-link'><Link url="mailto:info@keldan.is" targetBlank linkSize='18'>info@keldan.is</Link></div>. Uppsögn tekur gildi í lok þess mánaðar sem uppsögn berst. Lokast þá aðgengi áskrifanda að upplýsingaþjónustu Keldunnar eins og henni er lýst í samningi þessum og notkunarskilmálum.</span>
                                </li>

                                <li>
                                    <span>Keldan getur sagt samningi þessum upp með þriggja mánaða fyrirvara með því að senda tilkynningu í tölvupósti til áskrifanda á uppgefið netfang hans.</span>
                                </li>
                                <li>
                                    <span>Keldan áskilur sér rétt til að segja upp samningi þessum án fyrirvara gerist áskrifandi eða notandi á hans vegum brotlegur við notkunarskilmála eða önnur ákvæði samnings þessa.</span>
                                </li>

                                <li>
                                    <span>Ef um tilfallandi kaup er að ræða skal einungis c-liður ákvæðis þessa vera hluti af samningnum. Við tilfallandi kaup gildir samningur milli Keldunnar og tilfallandi kaupanda upplýsinga á meðan kaupandi hefur aðgang að upplýsingakerfi Keldunnar en rennur út um leið og aðgangur kaupanda lokast án sérstakrar uppsagnar.</span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                        <ScrollSpySection className="article__section" id="agreiningsmal-og-varnarthing" label="Lausn ágreiningsmála og varnarþing">
                            <h2>Lausn ágreiningsmála og varnarþing</h2>

                            <ol className="list-alphabets">
                                <li>
                                    <span>Samningur þessi lýtur íslenskum lögum. Verði ágreiningur gerður um framkvæmd samningsins eða túlkun skulu Keldan og áskrifandi eða tilfallandi kaupandi, eftir því sem við á, leitast við að leysa úr ágreiningi í sameiningu. Skulu þeir skipa einn sáttamann hvor sem gera tillögu að lausn ágreiningsins.</span>
                                </li>

                                <li>
                                    <span>Vegna ágreinings sem ekki leysist skv. lið a) skulu dómsmál rekin fyrir Héraðsdómi Reykjavíkur.</span>
                                </li>
                            </ol>
                        </ScrollSpySection>
                    </ScrollSpy>
                </div>
            </article>
        </div>
    )
}

export default Samningur;